import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Button from "../components/Button";

const SellerHero: React.FC = () => {
  const data = useStaticQuery<Queries.SellerHeroQuery>(query);

  return (
    <div className="relative flex justify-center items-center h-80 mb-8 md:mb-16">
      <div className="absolute inset-0">
        <GatsbyImage
          alt="A canola field"
          image={data.heroImage.childImageSharp.gatsbyImageData}
          className="h-full"
        />
      </div>
      <div className="relative px-4 sm:px-6 md:px-8">
        <h1
          style={{ fontFamily: "Arial" }}
          className="text-center text-white text-4xl font-bold leading-tight"
        >
          Selling Farm Land, It&apos;s All We Do
        </h1>
        <div className="flex justify-center mt-5 md:mt-7">
          <Button className="px-8" href="/contact">
            Contact Us Today
          </Button>
        </div>
      </div>
    </div>
  );
};

const query = graphql`
  query SellerHero {
    heroImage: file(relativePath: { eq: "hero-mobile-background.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 95
          placeholder: NONE
          transformOptions: { cropFocus: CENTER }
          layout: FULL_WIDTH
        )
      }
    }
  }
`;

export default SellerHero;
