import React from "react";
import Carousel from "react-multi-carousel";

import CarouselButtonGroup from "../components/CarouselButtonGroup";

import turnKey from "../images/turn-key.svg";
import maximizeValue from "../images/maximize-value.svg";
import worldClass from "../images/world-class.svg";
import strategic from "../images/strategic.svg";

const options = {
  sm: {
    breakpoint: { min: 0, max: 500 },
    items: 1,
  },
  md: {
    breakpoint: { min: 501, max: 992 },
    items: 2,
  },
  lg: {
    breakpoint: { min: 993, max: 1200 },
    items: 3,
  },
  xl: {
    breakpoint: { min: 1201, max: 10000 },
    items: 4,
  },
};

const SellerFeature: React.FC<{
  icon: string;
  alt: string;
  title: string;
  text: string;
}> = ({ icon, alt, title, text }) => (
  <div className="px-4 pointer-events-none">
    <img alt={alt} src={icon} className="mb-8" />
    <h2 className="text-lg font-bold">{title}</h2>
    <p className="text-base">{text}</p>
  </div>
);

const SellerFeatures: React.FC = () => (
  <div className="mb-10 mw-screen-size-xl mx-auto max-w-screen-xl">
    <Carousel
      arrows={false}
      responsive={options}
      renderButtonGroupOutside
      customButtonGroup={<CarouselButtonGroup />}
    >
      <SellerFeature
        icon={turnKey}
        alt="Key Icon"
        title="Turn-Key Experience"
        text="When it comes to selling farm or ranch land, our full service team works with you from your very first call right down to handing you a cheque at closing. We walk the entire walk with you."
      />
      <SellerFeature
        icon={maximizeValue}
        alt="Money Icon"
        title="Maximize Value"
        text="Anyone can create a sale, but you need lasting lifelong results. We monetize your land in a manner that ensure maximum value and benefit for the rest of you and your family’s life."
      />
      <SellerFeature
        icon={worldClass}
        alt="World Icon"
        title="World-Class Marketing"
        text="Finally: a specialized platform for selling agricultural land! All sales are seen worldwide, and are seen by all potential buyers — neighbours, friends, farmers, and investors."
      />
      <SellerFeature
        icon={strategic}
        alt="Checkmark Icon"
        title="Strategic Packaging & 'En Bloc'"
        text="Instead of the “take all or nothing” approach, our innovative ‘En Bloc’ feature for selected sales allows certain bidders to also bid on individual parcels and/or the entire farm."
      />
    </Carousel>
  </div>
);

export default SellerFeatures;
