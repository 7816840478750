import React from "react";
import { GatsbyImage, withArtDirection } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import Button from "../components/Button";

const SellerFooter: React.FC = () => {
  const data = useStaticQuery<Queries.SellerFooterQuery>(query);

  const sources = withArtDirection(
    data.mobileImage.childImageSharp.gatsbyImageData,
    [
      {
        media: `(min-width: 769px)`,
        image: data.desktopImage.childImageSharp.gatsbyImageData,
      },
    ]
  );

  return (
    <div className="relative">
      <GatsbyImage alt="Horse in a field" image={sources} className="h-80" />
      <div className="absolute top-0 left-0 right-0 bottom-0 px-4 py-4 flex flex-col items-center justify-center">
        <h4
          style={{ fontFamily: "Arial" }}
          className="text-2xl text-white text-center font-bold mb-8 lg:text-4xl"
        >
          You Only Sell Your Land Once,
          <br />
          Make Sure You Get It Right.
        </h4>
        <Button className="w-auto" href="/contact">
          Contact Us Today
        </Button>
      </div>
    </div>
  );
};

const query = graphql`
  query SellerFooter {
    mobileImage: file(relativePath: { eq: "seller-footer-bg-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    desktopImage: file(relativePath: { eq: "seller-footer-bg-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`;

export default SellerFooter;
