import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Carousel from "react-multi-carousel";

import CarouselButtonGroup from "../components/CarouselButtonGroup";

import quotationMarks from "../images/quotation-marks.svg";

const options = {
  sm: {
    breakpoint: { min: 0, max: 10000 },
    items: 1,
  },
};

const Testimonial: React.FC<{
  testimonial: {
    tagline: string;
    content: string;
    city: string;
    name: string;
    title: string;
  };
}> = ({ testimonial }) => (
  <div className="h-full mx-4 px-6 pb-16 border-2 border-clhbid-orange pointer-events-none select-none lg:mx-10 lg:pb-0 cursor-grab">
    <img
      className="absolute -top-10 left-0 w-full h-20 lg:top-12 lg:w-20 pointer-events-none select-none lg:rotate-180"
      alt=""
      src={quotationMarks}
    />
    <img
      className="hidden h-20 w-20 lg:block lg:absolute lg:right-0 lg:bottom-12"
      alt=""
      src={quotationMarks}
    />
    <div className="h-full w-full flex py-16 flex-col justify-center pointer-events-none select-none lg:max-w-2xl mx-auto">
      {testimonial.tagline && (
        <h1 className="text-center text-2xl mb-6">{testimonial.tagline}</h1>
      )}
      <p className="text-center whitespace-pre-line break-words mb-10">
        {testimonial.content}
      </p>
      <h5 className="text-center text-sm">
        {testimonial.name}
        <br />
        {testimonial.title}
        {testimonial.title && testimonial.city && " - "}
        {testimonial.city}
      </h5>
    </div>
  </div>
);

const SellerTestimonials: React.FC = () => {
  const data = useStaticQuery<Queries.SellerTestimonialsQuery>(query);

  return (
    <div className="relative mb-10 mx-auto max-w-screen-xl lg:mb-16">
      <Carousel
        className="pt-10 lg:pt-0 lg:mx-40"
        arrows={false}
        responsive={options}
        renderButtonGroupOutside
        customButtonGroup={
          <CarouselButtonGroup className="bottom-12 mx-6 lg:absolute lg:top-1/2 lg:left-16 lg:right-16" />
        }
      >
        {data.testimonials.nodes.map((testimonial) => (
          <Testimonial key={testimonial.name} testimonial={testimonial} />
        ))}
      </Carousel>
    </div>
  );
};

const query = graphql`
  query SellerTestimonials {
    testimonials: allTestimonialsYaml {
      nodes {
        tagline
        name
        title
        city
        content
      }
    }
  }
`;

export default SellerTestimonials;
