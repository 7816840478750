import React from "react";

import leftArrow from "../images/left-arrow.svg";
import rightArrow from "../images/right-arrow.svg";

const CarouselButtonGroup: React.FC<{
  next?: () => void;
  previous?: () => void;
  carouselState?: {
    currentSlide: number;
    totalItems: number;
    slidesToShow: number;
  };
  className?: string;
}> = ({
  next,
  previous,
  carouselState: { currentSlide, totalItems, slidesToShow },
  className,
}) => {
  const hasNext = currentSlide < totalItems - slidesToShow;
  const hasPrevious = currentSlide > 0;

  return (
    <div className={`relative h-8 ${className}`}>
      <button
        className={`absolute left-4 top-1 ${!hasPrevious ? "hidden" : ""}`}
        onClick={previous}
      >
        <img alt="Left Arrow" src={leftArrow} />
      </button>
      <button
        className={`absolute right-4 top-1 ${!hasNext ? "hidden" : ""}`}
        onClick={next}
      >
        <img alt="Left Arrow" src={rightArrow} />
      </button>
    </div>
  );
};

export default CarouselButtonGroup;
