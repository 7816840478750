import React from "react";

import { SellerPersuasionPage as seo } from "../helpers/seo";

import SEO from "../components/SEO";

import SellerHero from "../sections/SellerHero";
import SellerVideo from "../sections/SellerVideo";
import SellerLinks from "../sections/SellerLinks";
import SellerFooter from "../sections/SellerFooter";
import SellerFeatures from "../sections/SellerFeatures";
import SellerTestimonials from "../sections/SellerTestimonials";

const SellerPersuasionPage: React.FC = () => (
  <>
    <SEO title={seo.title} description={seo.description} />
    <SellerHero />
    <SellerFeatures />
    <SellerVideo />
    <SellerLinks />
    <SellerTestimonials />
    <SellerFooter />
  </>
);

export default SellerPersuasionPage;
