import React from "react";

import Button from "../components/Button";
import ResponsiveVideo from "../components/ResponsiveVideo";

const SellerVideo: React.FC = () => {
  return (
    <div className="mb-12 mx-auto max-w-screen-xl">
      <div className="bg-clhbid-orange px-4 py-8 lg:grid lg:grid-cols-5 lg:gap-x-12 xl:px-24 xl:py-16 xl:grid-cols-6">
        <div className="col-span-2 mb-3 xl:col-span-3">
          <ResponsiveVideo youtubeId="dz4vTo4q34I" />
        </div>
        <div className="pt-3 col-span-3 lg:pt-0">
          <h2 className="mb-8 font-medium">
            Sell with Western Canada&apos;s proven, leading-edge online tender
            platform.
          </h2>
          <p className="mb-8">
            When selling farmland, you want the platform that gives you the best
            results. That platform is CLHbid.com. We&apos;re a ‘specialty tool’
            designed specifically for selling farmland. No one knows better than
            farmers about using the right tool for the job.
          </p>
          <Button family="white" href="/contact">
            Contact Us Today
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SellerVideo;
