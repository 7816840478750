import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Button from "../components/Button";

const SellerLinks: React.FC = () => {
  const data = useStaticQuery<Queries.SellerLinksQuery>(query);

  return (
    <div className="px-4 mb-16 max-w-6xl mx-auto lg:flex lg:content-bottom">
      <div className="mb-10 order-1 lg:w-1/2 lg:pl-10 lg:mb-0">
        <GatsbyImage
          alt="Two people talking in a field"
          image={data.file.childImageSharp.gatsbyImageData}
          className="h-44 lg:h-full"
        />
      </div>
      <div className="shrink-1 grow-0 lg:w-1/2 lg:pt-16">
        <div className="mb-16">
          <h3 className="text-2xl mb-1">Meet The Team</h3>
          <p className="mb-8">
            Our team has legal, agricultural and business background with
            extensive experience in buying and selling Farm Land.
          </p>
          <Button href="/about-clhbid">Learn More</Button>
        </div>
        <div>
          <h3 className="text-2xl mb-1">Have Questions?</h3>
          <p className="mb-8">
            Call CLHbid.com and let our team maximize the value for your
            lifetime of work. We’re happy to answer any of your questions.
          </p>
          <Button href="/contact">Contact Us Today</Button>
        </div>
      </div>
    </div>
  );
};

const query = graphql`
  query SellerLinks {
    file(relativePath: { eq: "seller-links-hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          placeholder: NONE
          transformOptions: { cropFocus: CENTER }
          layout: FULL_WIDTH
        )
      }
    }
  }
`;

export default SellerLinks;
